
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import { Information as InformationModel } from '@/interfaces/models/Information';
import InformationTable from '../../components/information/InformationTable.vue';

const information = namespace('information');

@Component({
  components: { InformationTable, VEmptyState, VWrapper },
})
export default class Information extends Vue {
  @information.Action('fetch') public getInformation!: any;
  @information.Action('destroy') public deleteInformation!: any;
  @information.State((state) => state.pagination.total) public total!: number;
  @information.State('items') public items!: InformationModel[];

  public created() {
    this.$startLoading('information');
  }

  public async destroy(information: InformationModel) {
    this.$startLoading('information');
    await this.deleteInformation({ id: information._id });
    this.$stopLoading('information');
  }

  public async mounted() {
    this.$startLoading('information');
    await this.getInformation({ page: 1 });
    this.$stopLoading('information');
  }
}
