
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import VTable from '@/components/shared/table/VTable.vue';
import headers from './headers';
import { Tag } from '@/interfaces/models/Tag';
import { Information } from '@/interfaces/models/Information';

@Component({
  components: { VTable },
})
export default class InformationTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Tag[];
  @Prop({ type: Number, required: true }) public total!: number;

  public headers: ColumnHeader[] = headers;

  public edit(item: Information) {
    this.$router.push({ name: 'information.edit', params: { id: item._id }, query: { ...this.$route.query } });
  }
}
