export default [
  {
    text: 'information.table.title',
    value: 'title',
  },
  {
    text: 'information.table.description',
    value: 'description',
  },
  {
    text: 'information.table.url',
    value: 'url',
  },
  {
    text: 'information.table.from',
    value: 'from',
    type: 'date',
    format: 'DD.MM.YYYY',
  },
  {
    text: 'information.table.to',
    value: 'to',
    type: 'date',
    format: 'DD.MM.YYYY',
  },
];
